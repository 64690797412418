import styles from './About.module.scss';

const people: Person[] = [
   {
      name: "Nate",
      image: "https://avatars.githubusercontent.com/u/36091727?v=4",
      url: "https://www.linkedin.com/in/natewu/",
      roles: [
         "Software Developer",
         "UI/UX",
      ],
      email: "nate@nexoft.dev"
   },
   {
      name: "Ryan",
      image: "https://avatars.githubusercontent.com/u/25887009?v=4",
      url: "https://www.linkedin.com/in/ryanwongyyc/",
      roles: [
         "Software Developer",
         "UI/UX",
      ],
      email: "ryan@nexoft.dev"
   }
]

interface Person {
   name: string;
   image: string;
   url: string;
   roles: string[];
   email?: string;
}

export default function About() {
   return (
      <div className={styles.About}>
         <h1 className={styles.hero} id="about">about</h1>
         <div className={styles.content}>
         {
            people.map((person) => (
               <PersonInfo key={person.name} {...person} />
            ))
         }
         </div>
      </div>
   );
}

function PersonInfo(person: Person) {
   function handleClick() {
      window.open(person.url, "_blank");
   }

   return (
      <div className={styles.Person__wrapper}>
         <div className={styles.Person} onClick={handleClick}>
            <img className={styles.image} src={person.image} alt={person.name} />
            <h2 className={styles.name}>{person.name}</h2>
            <p className={styles.email}>{person.email}</p>
            <div className={styles.roles}>
               <h3 className={styles.roles__hero}>Roles</h3>
               {
                  person.roles.map((role, index) => (
                     <p key={index} className={styles.role}>{role}</p>
                  ))
               }
            </div>
         </div>
      </div>
      
   );
}