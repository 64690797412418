import About from "./components/about/About";
import ChevronDown from "./assets/ChevronDown";
import styles from './App.module.scss';

function App() {
   function handleActionDown(){
      document.getElementById("about")!.scrollIntoView({ behavior: "smooth" });
   }

   return (
      <div className={styles.App}>
         <div className={styles.landing}>
            <div className={styles.main}>
               <h1 className={styles.hero}>nexoft</h1>
            </div>
            <div className={styles.action}>
               <ChevronDown className={styles.chevron} onClick={handleActionDown} />
            </div>
         </div>
         <About/>
      </div>
   );
}

export default App;
